<template>
     <b-card>
		<b-row>
			<b-button
				variant="relief-success" 
				v-b-modal.modal-store-amount
				title="Adicionar"
				class="ml-1"
				v-b-tooltip.hover.v-success
			>
				Adicionar 
				<feather-icon icon="PlusIcon" />
			</b-button>
		</b-row>
		<b-overlay :show="isLoadingAmounts" opacity="0.70" rounded="sm">
		<b-table-simple
			striped
			stacked="md"
			class="mt-2 responsive text-center"
			id="tableAmount"
			:item="fetchAmount"
			:per-page="perPageAmount"
			:current-page="currentPageAmount"
		>
			<b-thead>
			<b-tr class="text-center">
				<b-th>Nome</b-th>
				<b-th>Ação</b-th>
			</b-tr>
			</b-thead>
			<b-tbody>
			<b-tr :key="indextr" v-for="(tr, indextr) in amounts">
				<b-td>{{tr.name}}</b-td>
				<b-td>
				<b-button
					v-ripple.400="'rgba(40, 199, 111, 0.15)'"
					variant="flat-warning"
					class="btn-icon rounded-circle"
					@click="showDetailAmount(tr.id)"
					v-b-modal.modal-update-amount
				>
					<feather-icon icon="Edit2Icon" />
				</b-button>

				<b-button
					v-ripple.400="'rgba(40, 199, 111, 0.15)'"
					variant="flat-danger"
					class="btn-icon rounded-circle"
					v-b-modal.modal-datele-amount
					@click="showDetailAmount(tr.id)"
				>
					<feather-icon icon="Trash2Icon" />
				</b-button>
				</b-td>
			</b-tr>
			</b-tbody>
		</b-table-simple>
		</b-overlay>
		<b-pagination
			@change="handlePageChangeAmount"
			v-model="currentPageAmount"
			:per-page="perPageAmount"
			:total-rows="totalRowsAmount"
			aria-controls="tableAmount"
			class="mt-1"
		/>
		<b-modal
			id="modal-store-amount"
			modal-class="modal-success"
			ref="modal-store-amount"
			centered
			title="Cadastrar Quantidade"
			>
			<b-card-text>
				<label> Nome </label>
				<b-form-input
				placeholder="Nome"
				v-model="dataAmount.name"
				id="name"
				/>
			</b-card-text>
			<template #modal-footer>
				<b-button
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				variant="success"
				class="mr-1"
				@click="storeAmount"
				>
				Cadastrar
				</b-button>
			</template>
			</b-modal>

			<b-modal
			id="modal-datele-amount"
			ok-variant="danger"
			modal-class="modal-danger"
			ref="modal-datele-amount"
			centered
			title="Deletar Quantidade"
			>
			<b-card-text>
				Você está preste a deletar esta quantidade do sistema:
				<hr>
				<p> <b>Titulo: </b>{{amountModel ? amountModel.name : ''}} </p>
				<hr>
				Por favor, confirme a exclusão antes de prosseguir.
				<b-form-checkbox
				id="checkbox-1"
				@change="newDeleteStatus"
				v-model="DeleteStatus"
				name="checkbox-1"
				value="accepted"
				unchecked-value="null"
				>
				Concordo em excluir esta mensagem
				</b-form-checkbox>
			</b-card-text>
			<template #modal-footer>
				<b-col>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					class="mt-2"
					variant="outline-danger"
					block
					:disabled="DeleteStatus == null"
					@click="deleteAmount(amountModel.id)"
				>
					Deletar
				</b-button>
				</b-col>
			</template>
			</b-modal>

			<b-modal
			id="modal-update-amount"
			ok-variant="warning"
			modal-class="modal-warning"
			ref="modal-update-amount"
			centered
			title="Editar Quantidade"
			>
			<b-card-text>
				Você está preste a editar esta quantidade do sistema:
				<hr>
			</b-card-text>
			<div v-if="amountModel">
				<label class="mt-1"> Novo Nome </label>
				<b-form-input
				placeholder="Novo Nome"
				id="title"
				@change="newNameAmount"
				:value="updateAmounts.name  = amountModel.name"
				>
				{{amountModel.name}}
				</b-form-input>
			</div>
			<div v-else>
				Quantidade não encontrada
			</div>
			<hr>
			<template #modal-footer>
				<b-col>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					class="mt-2"
					variant="outline-warning"
					block
					:value="updateAmounts.id = amountModel.id"
					@click="updateAmount"
				>
					Editar
				</b-button>
				</b-col>
			</template>
		</b-modal>
	</b-card>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		BCard,
	},
	directives: {
		Ripple,
	},
	data() {
    	return {
			showSearch: true,
			isLoadingAmounts: true,

			csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

			perPageAmount: 10,
			currentPageAmount: 1,
			totalRowsAmount: null,

			amountModel: null,

			DeleteStatus: null,

			dataAmount: {
				name: null,
				imported_name: null
			},

			updateAmounts: {
				id: null,
				name: 'qtd'
			},
		};
	},
	computed: {
    	...mapState("amounts", ["amounts"]),
  	},
  	methods: {
		...mapActions("amounts", ["storeAmounts",]),

		newDeleteStatus(value){
			this.DeleteStatus = value;
		},

		fetchAmount(paginated = true, page = 1) {
      		this.$store
			.dispatch("amounts/fetchAmount", {
			paginated: paginated,
			page: page,
			perPage: this.perPageAmount,
			})
			.then((response) => {
			this.totalRowsAmount = response.total;
			})
			.catch((err) => {
			console.error(err);
			})
			.finally(() => {
			});
    },

		showDetailAmount(id){
		this.amountModel = this.$store.getters['amounts/getAmountsById'](id)
		},

		storeAmount() {
		this.storeAmounts({
			...this.dataAmount,
		})
		.then(() => {
			this.$toast({
			component: ToastificationContent,
			props: {
				title: 'Cadastrado com sucesso!',
				icon: 'ThumbsUpIcon',
				text: 'Operação executada com sucesso.',
				variant: 'success'
			},
			})   
		})
		.finally(() =>{
			this.fetchAmount()
			this.$refs['modal-store-amount'].hide()
		});
		},
		
		newNameAmount(value){
		this.updateAmounts.name = value;
		},

		updateAmount() { 
		let data = {
			...this.updateAmounts,
		}; 
		this.$store.dispatch("amounts/updateAmount", data)
		.then(() => { 
			this.$toast({
			component: ToastificationContent,
			props: {
				title: 'Atualizado com sucesso!',
				icon: 'Edit2Icon',
				text: 'Operação executada com sucesso.',
				variant: 'warning'
			},
			})   
		})
		.catch(err => { 
			console.error(err); 
		})
		.finally(() =>{
			this.fetchAmount()
			this.$refs['modal-update-amount'].hide()
		});
		},

		deleteAmount(id) { 
		this.isLoadingAmounts = true;
		let data = id; 
		this.$store.dispatch("amounts/deleteAmount", data)
		.then(() => { 
			this.$toast({
			component: ToastificationContent,
			props: {
				title: 'Excluído com sucesso!',
				icon: 'Trash2Icon',
				text: 'Operação executada com sucesso.',
				variant: 'danger'
			},
			}),
			this.isLoadingAmounts = false;
			this.DeleteStatus = null
		})
		.catch(err => { 
			console.error(err); 
		})
		.finally(() =>{
			this.fetchAmount()
			this.$refs['modal-delete-medicament'].hide()
			this.DeleteStatus = null
		});
		},

		handlePageChangeAmount(value) {
			this.fetchAmount(true, value);
		},

  	},
	created() {
		this.fetchAmount();
		if (! this.csrf) {
		console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
		}
  	},
	watch: {
		amounts(){
			this.isLoadingAmounts = false
		},
  },
}
</script>
